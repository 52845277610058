#register .view {
  background: url('../../assets/img/forest-bg.jpg')
    no-repeat center center;
  background-size: cover;
  height: 100vh;
}

#register .card {
  background-color: rgba(255, 255, 255, 0.85);
}

#register h6 {
  line-height: 1.7;
}

#register .form-check {
  margin-bottom: 0 !important;
}

#register .rgba-gradient {
  background: linear-gradient(98deg,
  rgba(22, 91, 231, 0.5),
  rgba(255, 32, 32, 0.5) 100%);
}
