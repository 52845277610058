
.masonry-with-columns {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    max-height: 1000px; }
    .masonry-with-columns div {
      flex: 1 0 auto;
      background: #00997B;
      color: white;
      margin: 0 1rem 1rem 0;
      text-align: center;
      font-weight: 900;
      font-size: 2rem; }
    .masonry-with-columns div:nth-child(1) {
      height: 206px;
      line-height: 206px; }
    .masonry-with-columns div:nth-child(2) {
      height: 196px;
      line-height: 196px; }
    .masonry-with-columns div:nth-child(3) {
      height: 201px;
      line-height: 201px; }
    .masonry-with-columns div:nth-child(4) {
      height: 463px;
      line-height: 463px; }
    .masonry-with-columns div:nth-child(5) {
      height: 422px;
      line-height: 422px; }
    .masonry-with-columns div:nth-child(6) {
      height: 191px;
      line-height: 191px; }
    .masonry-with-columns div:nth-child(7) {
      height: 490px;
      line-height: 490px; }
    .masonry-with-columns div:nth-child(8) {
      height: 475px;
      line-height: 475px; }
    .masonry-with-columns div:nth-child(9) {
      height: 330px;
      line-height: 330px; }
    .masonry-with-columns div:nth-child(10) {
      height: 246px;
      line-height: 246px; }
    .masonry-with-columns div:nth-child(11) {
      height: 204px;
      line-height: 204px; }
    .masonry-with-columns div:nth-child(12) {
      height: 179px;
      line-height: 179px; }
    .masonry-with-columns div:nth-child(13) {
      height: 338px;
      line-height: 338px; }
    .masonry-with-columns div:nth-child(14) {
      height: 429px;
      line-height: 429px; }
    .masonry-with-columns div:nth-child(15) {
      height: 459px;
      line-height: 459px; }
    .masonry-with-columns div:nth-child(16) {
      height: 150px;
      line-height: 150px; }
    .masonry-with-columns div:nth-child(17) {
      height: 212px;
      line-height: 212px; }
    .masonry-with-columns div:nth-child(18) {
      height: 460px;
      line-height: 460px; }
    .masonry-with-columns div:nth-child(19) {
      height: 431px;
      line-height: 431px; }
    .masonry-with-columns div:nth-child(20) {
      height: 238px;
      line-height: 238px; }
    .masonry-with-columns div:nth-child(21) {
      height: 156px;
      line-height: 156px; }
    .masonry-with-columns div:nth-child(22) {
      height: 275px;
      line-height: 275px; }
    .masonry-with-columns div:nth-child(23) {
      height: 207px;
      line-height: 207px; }
    .masonry-with-columns div:nth-child(24) {
      height: 158px;
      line-height: 158px; }
    .masonry-with-columns div:nth-child(25) {
      height: 287px;
      line-height: 287px; }
    .masonry-with-columns div:nth-child(26) {
      height: 162px;
      line-height: 162px; }
    .masonry-with-columns div:nth-child(27) {
      height: 460px;
      line-height: 460px; }
    .masonry-with-columns div:nth-child(28) {
      height: 175px;
      line-height: 175px; }
    .masonry-with-columns div:nth-child(29) {
      height: 298px;
      line-height: 298px; }
    .masonry-with-columns div:nth-child(30) {
      height: 159px;
      line-height: 159px; }
    .masonry-with-columns div:nth-child(31) {
      height: 129px;
      line-height: 129px; }
    .masonry-with-columns div:nth-child(32) {
      height: 470px;
      line-height: 470px; }
    .masonry-with-columns div:nth-child(33) {
      height: 118px;
      line-height: 118px; }
    .masonry-with-columns div:nth-child(34) {
      height: 224px;
      line-height: 224px; }
    .masonry-with-columns div:nth-child(35) {
      height: 168px;
      line-height: 168px; }
    .masonry-with-columns div:nth-child(36) {
      height: 140px;
      line-height: 140px; }
  
  .masonry-with-columns-2 {
    display: flex;
    flex-wrap: wrap; }
    .masonry-with-columns-2 div {
      height: 150px;
      line-height: 150px;
      background: #9B1B30;
      color: white;
      margin: 0 1rem 1rem 0;
      text-align: center;
      font-weight: 900;
      font-size: 2rem;
      flex: 1 0 auto; }
    .masonry-with-columns-2 div:nth-child(1) {
      width: 345px; }
    .masonry-with-columns-2 div:nth-child(2) {
      width: 74px; }
    .masonry-with-columns-2 div:nth-child(3) {
      width: 424px; }
    .masonry-with-columns-2 div:nth-child(4) {
      width: 267px; }
    .masonry-with-columns-2 div:nth-child(5) {
      width: 239px; }
    .masonry-with-columns-2 div:nth-child(6) {
      width: 169px; }
    .masonry-with-columns-2 div:nth-child(7) {
      width: 117px; }
    .masonry-with-columns-2 div:nth-child(8) {
      width: 372px; }
    .masonry-with-columns-2 div:nth-child(9) {
      width: 457px; }
    .masonry-with-columns-2 div:nth-child(10) {
      width: 415px; }
    .masonry-with-columns-2 div:nth-child(11) {
      width: 183px; }
    .masonry-with-columns-2 div:nth-child(12) {
      width: 241px; }
    .masonry-with-columns-2 div:nth-child(13) {
      width: 333px; }
    .masonry-with-columns-2 div:nth-child(14) {
      width: 432px; }
    .masonry-with-columns-2 div:nth-child(15) {
      width: 413px; }
    .masonry-with-columns-2 div:nth-child(16) {
      width: 168px; }
    .masonry-with-columns-2 div:nth-child(17) {
      width: 307px; }
    .masonry-with-columns-2 div:nth-child(18) {
      width: 133px; }
    .masonry-with-columns-2 div:nth-child(19) {
      width: 422px; }
    .masonry-with-columns-2 div:nth-child(20) {
      width: 109px; }
    .masonry-with-columns-2 div:nth-child(21) {
      width: 204px; }
    .masonry-with-columns-2 div:nth-child(22) {
      width: 175px; }
    .masonry-with-columns-2 div:nth-child(23) {
      width: 368px; }
    .masonry-with-columns-2 div:nth-child(24) {
      width: 126px; }
    .masonry-with-columns-2 div:nth-child(25) {
      width: 184px; }
    .masonry-with-columns-2 div:nth-child(26) {
      width: 279px; }
    .masonry-with-columns-2 div:nth-child(27) {
      width: 143px; }
    .masonry-with-columns-2 div:nth-child(28) {
      width: 220px; }
    .masonry-with-columns-2 div:nth-child(29) {
      width: 77px; }
    .masonry-with-columns-2 div:nth-child(30) {
      width: 71px; }
    .masonry-with-columns-2 div:nth-child(31) {
      width: 171px; }
    .masonry-with-columns-2 div:nth-child(32) {
      width: 129px; }
    .masonry-with-columns-2 div:nth-child(33) {
      width: 214px; }
    .masonry-with-columns-2 div:nth-child(34) {
      width: 216px; }
    .masonry-with-columns-2 div:nth-child(35) {
      width: 354px; }
    .masonry-with-columns-2 div:nth-child(36) {
      width: 436px; }
  
  .masonry-with-flex {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    max-height: 1000px; }
    .masonry-with-flex div {
      width: auto;
      background: #975A58;
      color: white;
      margin: 0 1rem 1rem 0;
      text-align: center;
      font-weight: 900;
      font-size: 2rem; }
    .masonry-with-flex div:nth-child(1) {
      height: 461px;
      line-height: 461px; }
    .masonry-with-flex div:nth-child(2) {
      height: 324px;
      line-height: 324px; }
    .masonry-with-flex div:nth-child(3) {
      height: 280px;
      line-height: 280px; }
    .masonry-with-flex div:nth-child(4) {
      height: 381px;
      line-height: 381px; }
    .masonry-with-flex div:nth-child(5) {
      height: 109px;
      line-height: 109px; }
    .masonry-with-flex div:nth-child(6) {
      height: 288px;
      line-height: 288px; }
    .masonry-with-flex div:nth-child(7) {
      height: 399px;
      line-height: 399px; }
    .masonry-with-flex div:nth-child(8) {
      height: 230px;
      line-height: 230px; }
    .masonry-with-flex div:nth-child(9) {
      height: 485px;
      line-height: 485px; }
    .masonry-with-flex div:nth-child(10) {
      height: 408px;
      line-height: 408px; }
    .masonry-with-flex div:nth-child(11) {
      height: 413px;
      line-height: 413px; }
    .masonry-with-flex div:nth-child(12) {
      height: 425px;
      line-height: 425px; }
    .masonry-with-flex div:nth-child(13) {
      height: 323px;
      line-height: 323px; }
    .masonry-with-flex div:nth-child(14) {
      height: 370px;
      line-height: 370px; }
    .masonry-with-flex div:nth-child(15) {
      height: 214px;
      line-height: 214px; }
    .masonry-with-flex div:nth-child(16) {
      height: 199px;
      line-height: 199px; }
    .masonry-with-flex div:nth-child(17) {
      height: 164px;
      line-height: 164px; }
    .masonry-with-flex div:nth-child(18) {
      height: 453px;
      line-height: 453px; }
    .masonry-with-flex div:nth-child(19) {
      height: 442px;
      line-height: 442px; }
    .masonry-with-flex div:nth-child(20) {
      height: 339px;
      line-height: 339px; }
    .masonry-with-flex div:nth-child(21) {
      height: 168px;
      line-height: 168px; }
    .masonry-with-flex div:nth-child(22) {
      height: 373px;
      line-height: 373px; }
    .masonry-with-flex div:nth-child(23) {
      height: 131px;
      line-height: 131px; }
    .masonry-with-flex div:nth-child(24) {
      height: 256px;
      line-height: 256px; }
    .masonry-with-flex div:nth-child(25) {
      height: 152px;
      line-height: 152px; }
    .masonry-with-flex div:nth-child(26) {
      height: 355px;
      line-height: 355px; }
    .masonry-with-flex div:nth-child(27) {
      height: 267px;
      line-height: 267px; }
    .masonry-with-flex div:nth-child(28) {
      height: 190px;
      line-height: 190px; }
    .masonry-with-flex div:nth-child(29) {
      height: 433px;
      line-height: 433px; }
    .masonry-with-flex div:nth-child(30) {
      height: 146px;
      line-height: 146px; }
    .masonry-with-flex div:nth-child(31) {
      height: 190px;
      line-height: 190px; }
    .masonry-with-flex div:nth-child(32) {
      height: 156px;
      line-height: 156px; }
    .masonry-with-flex div:nth-child(33) {
      height: 500px;
      line-height: 500px; }
    .masonry-with-flex div:nth-child(34) {
      height: 109px;
      line-height: 109px; }
    .masonry-with-flex div:nth-child(35) {
      height: 293px;
      line-height: 293px; }
    .masonry-with-flex div:nth-child(36) {
      height: 169px;
      line-height: 169px; }
  